import React, { useState, useEffect } from "react";
import { Container, Table } from "reactstrap";
import { Button, Row, Col, Input, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { getAllOrders } from "../../network/ApiAxios";
import ProductPagination from "../../views/pagination/productPagination";

const OrderTable = () => {
  const [orders, setOrders] = useState([]);
  const [pageData, setPageData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [sortValue, setSortValue] = useState(-1);

  const getAll = async (
    page = 1,
    search = searchText,
    sort = sortValue,
    status = statusValue
  ) => {
    const data = await getAllOrders({ status, page, search, sort });
    if (data && data.success && data.data) {
      setOrders(data.data);
      setPageData({
        pageCount: data.pageCount,
        currentPage: data.currentPage,
        nextPage: data.nextPage,
        allCount: data.allOrdersCount,
      });
    }
  };

  const sortOrders = () => {
    if (sortValue === "") {
      setSortValue(1);
    } else if (sortValue === 1) {
      setSortValue(-1);
    } else if (sortValue === -1) {
      setSortValue("");
    }
    getAll();
  };

  const clearAllFilters = () => {
    setSearchText("");
    setSortValue("");
    setStatusValue("");
    getAll(1, "", "", "");
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getAll(1, searchText);
  };

  useEffect(() => {
    getAll(1);
    // eslint-disable-next-line
  }, [statusValue, searchText, sortValue]);

  return (
    <div className="mt-4">
      <Container>
        <Row>
          <Col>
            <Row>
              <Col md="3">
                <form onSubmit={onSubmitSearch} id="searchSubmit">
                  <FormGroup>
                    <Input
                      placeholder="Qiriruv..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </FormGroup>
                </form>
              </Col>
              <Col md="2">
                <Button onClick={clearAllFilters}>Filterni tozalash</Button>
              </Col>
              <Col md="2">
                <Input
                  type="select"
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <option value={""}>Hammasi</option>
                  <option value={"Payment"}>Payment</option>
                  <option value={"Pending"}>Pending</option>
                  <option value={"Cancelled"}>Cancelled</option>
                  <option value={"Delivered"}>Delivered</option>
                </Input>
              </Col>
              <Col>
                <h1>Barcha buyurtmalar {pageData.allCount} ta</h1>
              </Col>
            </Row>
            {orders.length > 0 ? (
              <Table className="mt-4">
                <thead>
                  <tr align="center">
                    <th className="align-middle">T/R</th>
                    <th className="align-middle">
                      Buyurtma <br /> raqami
                    </th>
                    <th className="align-middle">Status</th>
                    <th className="align-middle">
                      Sana{" "}
                      <Button
                        className={`btn btn-sm ${
                          sortValue === 1
                            ? "btn-success"
                            : sortValue === -1
                            ? "btn-danger"
                            : ""
                        }`}
                        onClick={sortOrders}
                      >
                        A-z
                      </Button>
                    </th>
                    <th className="align-middle">Manzil</th>
                    <th className="align-middle text-center">
                      Telefon <br /> raqami
                    </th>
                    <th className="align-middle text-center">
                      Mahsulot <br /> soni
                    </th>
                    <th>{""}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((item, index) => (
                    <tr align="center" key={item._id}>
                      <td>{index + 1 + (pageData.currentPage - 1) * 10}</td>
                      <td>{item.orderNumber}</td>
                      <td>{item.status}</td>
                      <td>
                        {dayjs(new Date(item.createdAt)).format(
                          "YYYY-DD-MM HH:mm"
                        )}
                      </td>
                      <td>{item.address.address}</td>
                      <td>{item.customer.phone}</td>
                      <td>{item.products.length}</td>
                      <td>
                        <Link to={"/admin/order/" + item._id}>
                          <Button color="info" className="btn-sm">
                            I
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h1 className="mt-4">Buyurtma mavjud emas!</h1>
            )}
            {pageData.pageCount > 1 ? (
              <ProductPagination getAll={getAll} pageData={pageData} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderTable;
