import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { useParams } from "react-router-dom";
import { getByIdCustomer } from "../../network/ApiAxios";
import { Container } from "reactstrap";

const OneCustomer = (props) => {
  const { id } = useParams();
  const [customer, setCustomer] = useState("");
  const { phone, addresses, basket, favorites, orders } = customer;

  useEffect(() => {
    const getCustomer = async () => {
      const data = await getByIdCustomer(id);
      if (data && data.data) {
        setCustomer(data.data);
      } else {
        props.history.push("/");
      }
    };
    getCustomer();
  }, [id, props.history]);
  return (
    <div>
      <Header />
      <Container className="mt-4">
        <h2 className="bg-light text-center p-1">Xaridor haqida</h2>
        <h2 className="mt-3">Telefon raqami: {phone}</h2>
        {customer && addresses.length > 0 ? (
          <div>
            <h2 className="bg-green text-center text-white p-1 mt-3">
              Manzillari
            </h2>
            <ol>
              {addresses.map((item) => (
                <li key={item._id}>
                  <b>Manzil</b>
                  <ul>
                    <li>Manzil: {item.address}</li>
                    <li>Qabul qiluvchining nomeri: {item.recipientPhone}</li>
                    <li>Qabul qiluvchining ismi: {item.recipientName}</li>
                    <li>Eshik kodi: {item.doorCode}</li>
                    <li>
                      Qoldirish joyi:{" "}
                      {item.placeOfAbandonment === "door"
                        ? "Eshik oldida"
                        : item.placeOfAbandonment === "guard"
                        ? "Qorovulxonada"
                        : "Boshqa joyda"}
                    </li>
                    <li>
                      Asosiy manzilmi: {item.activeAddress ? "Ha" : "Yo'q"}
                    </li>
                    <li>Bino va xona raqami: {item.roomAndBuildingNumber}</li>
                  </ul>
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <h2>Address mavjud emas!</h2>
        )}
        {customer && basket.length > 0 ? (
          <div>
            <h2 className="bg-green text-center text-white p-1">
              Savatchadagi mahsulotlari
            </h2>
            <ol>
              {customer.basket.map((item) => (
                <li key={item._id}>
                  <b>Mahsulot</b>
                  <ul>
                    <li>
                      Miqdori: {item.amount} {item.product.unitOfAmount}
                    </li>
                    <li>Mahsulot: {item.product.productName}</li>
                    <li>Bar code: {item.product.barCode}</li>
                    <li>Yaroqlik muddati: {item.product.expirationDate}</li>
                  </ul>
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <h2>Savatcha bo'sh!</h2>
        )}
        {customer && favorites.length > 0 ? (
          <div>
            <h2 className="bg-green text-center text-white p-1">
              Sevimlilardagi mahsulotlari
            </h2>
            <ol>
              {favorites.map((item) => (
                <li key={item._id}>
                  <b>Sevimli</b>
                  <ul>
                    <li>Mahsulot: {item.productName}</li>
                    <li>Bar code: {item.barCode}</li>
                    <li>Yaroqlik muddati: {item.expirationDate}</li>
                  </ul>
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <h2>Sevimlilar bo'sh!</h2>
        )}
        {customer && orders.length > 0 ? (
          <div>
            <h2 className="bg-green text-center text-white p-1">
              Buyurtmalari
            </h2>
            <ol>
              {orders.map((item) => (
                <li key={item._id}>
                  <b>Buyurtma</b>
                  <ul>
                    <li>Buyurtma statusi: {item.status}</li>
                    <li>Buyurtma raqami: {item.orderNumber}</li>
                    <li>
                      Buyurtma sanasi:{" "}
                      {dayjs(new Date(item.createdAt)).format(
                        "YYYY-DD-MM HH:mm"
                      )}
                    </li>
                    <li>
                      <ol>
                        {item.products.map((item) => (
                          <li key={item._id}>
                            Mahsulot
                            <ul>
                              <li>
                                Miqdori: {item.amount}{" "}
                                {item.product.unitOfAmount}
                              </li>
                              <li>Mahsulot: {item.product.productName}</li>
                              <li>Bar code: {item.product.barCode}</li>
                              <li>
                                Sotilish narxi: {item.product.sellingPrice}
                              </li>
                              <li>
                                Yaroqlik muddati: {item.product.expirationDate}
                              </li>
                            </ul>
                          </li>
                        ))}
                      </ol>
                    </li>
                  </ul>
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <h2>Buyurtmalari yo'q!</h2>
        )}
      </Container>
    </div>
  );
};

export default OneCustomer;
